<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        {{ $t('Your package') }}
      </b-card-title>
      <b-card-text class="d-flex justify-content-between">
        <span>{{ subscription.title }}</span>
        <span>{{ subscription.price | price }}</span>
      </b-card-text>
      <b-list-group class="list-group-circle text-left">
        <b-list-group-item
          v-for="(bullet, index) in subscription.bullets"
          :key="index"
        >
          {{ bullet.description }}
        </b-list-group-item>
      </b-list-group>
      <hr />
      <b-card-text class="d-flex justify-content-between">
        <span>{{ $t('Total amount') }}</span>
        <span>{{ subscription.price | price }}</span>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import { price } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BListGroup,
    BListGroupItem
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  filters: {
    price
  },
  data() {
    return {
      
    }
  }
}
</script>

<style scoped>

</style>
