<template>
  <b-card>
    <div class="w-75 mb-3">
      <b-card-title>
        {{ $t('Congratulations for your purchase!') }}
      </b-card-title>
      <b-card-text>
        {{ $t('We have sent you an email about next steps after your purchase.') }}
      </b-card-text>
      <b-card-text>
        {{ $t('After completing your payment you will be able to send mentorship requests!') }}
      </b-card-text>
    </div>
    <b-button variant="primary" :to="{name: 'our-mentors'}">
      {{ $t('View mentors') }}
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BImg
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BImg
  },
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>

</style>
