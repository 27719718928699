<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        {{ $t('Available payment methods') }}
      </b-card-title>
      <b-card-sub-title class="mb-2">
        {{ $t('You can purchase a mentoring package in one of the following ways.') }}
      </b-card-sub-title>
      <b-list-group class="list-group-circle text-left mb-4">
        <b-list-group-item
          v-for="(method, index) in paymentMethods"
          :key="index"
        >
          {{ $t(method.name) }}
        </b-list-group-item>
      </b-list-group>
      <div class="mb-1">
        <b-button variant="primary" @click="purchase" :disabled="purchaseInProgress">
          {{ $t('Mentoring package purchase') }}
        </b-button>
      </div>
      <b-card-text>
        {{ $t('Press \"package purchase\" button and an email with all the details about the next steps will be sent to your email.') }}
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BListGroup,
  BListGroupItem,
  BRow
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardSubTitle,
    BCardText,
    BCardTitle,
    BCol,
    BListGroup,
    BListGroupItem,
    BRow
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      paymentMethods: [{
        name: 'Paypal'
      }, {
        name: 'Bank tranfer'
      }],
      purchaseInProgress: false
    }
  },
  methods: {
    purchase() {
      this.purchaseInProgress = true
      this.$store.dispatch('user/purchaseSubscription', this.subscription.id)
        .then(response => {
          this.purchaseInProgress = false
          this.$emit('purchase-completed')
        })
        .catch(error => {
          this.purchaseInProgress = false
        })
    }
  }
}
</script>

<style scoped>

</style>
