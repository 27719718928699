<template>
  <div>
    <page-header class="mb-3" :title="pageHeaderTitle" :message="pageHeaderMessage" />
    <b-row v-if="subscription" class="match-height">
      <b-col sm="12" md="8">
        <thank-you v-if="showThankYou" />
        <payment v-else :subscription="subscription" @purchase-completed="purchaseCompleted" />
      </b-col>
      <b-col sm="12" md="4">
        <subscription-summary :subscription="subscription" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow
} from 'bootstrap-vue'
import Payment from '@mentoring-platform/views/components/blocks/Checkout/Payment.vue'
import SubscriptionSummary from '@mentoring-platform/views/components/blocks/Checkout/SubscriptionSummary.vue'
import ThankYou from '@mentoring-platform/views/components/blocks/Checkout/ThankYou.vue'
import { useSubscriptionRepository } from '@mentoring-platform/composables'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    PageHeader,
    Payment,
    SubscriptionSummary,
    ThankYou
  },
  data() {
    return {
      showThankYou: false
    }
  },
  computed: {
    pageHeaderTitle() {
      return this.showThankYou ? this.$t('Thank you!') : this.$t('Package purchase')
    },
    pageHeaderMessage() {
      return this.showThankYou ? this.$t('Your order has been registered.') : this.$t('Complete your order and you will receive and email with the next steps.')
    },
    subscription() {
      return this.getSpecificAvailableSubscription(this.$route.params.id)
    }
  },
  methods: {
    purchaseCompleted() {
      this.showThankYou = true
    }
  },
  setup() {
    const { getSpecificAvailableSubscription } = useSubscriptionRepository()

    return {
      getSpecificAvailableSubscription
    }
  }
}
</script>

<style scoped>

</style>
